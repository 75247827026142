// Forms
.form-control.full-width {
  width: 100% !important;
}

select.with-errors, input.with-errors, div.select2-error.select2-with-errors span.select2-selection {
  background-image: linear-gradient(#ff4444, #ff4444), linear-gradient(rgba(120, 130, 140, 0.65), rgba(120, 130, 140, 0.65)) !important;
  background-size: 100% 2px, 100% 1px !important;
}

div.with-errors {
  color: #ff4444;
}

// Topbar
a.logo b img {
  max-height: 60px;
}

div.top-left-part {
  background-color: @primary;
  height: 62px;
}

// Panel Title Collapse Toggle
.clickable {
  cursor: pointer;
}

.clickable .glyphicon {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 17px;
}

.panel-heading span {
  margin-top: -20px;
  font-size: 15px;
}

a.clickable {
  color: inherit;
}

a.clickable:hover {
  text-decoration: none;
}

.p-r-25 {
  padding-right: 25px;
}

.dataTable {
  width: 100% !important;
}

#tv_map, #radio_map {
  height: 300px;
  width: 100%;
  overflow: hidden;
  float: left;
  border: thin solid #333;
}

#vendor_template_modal .modal-dialog {
  width: 90%;
}

li.select2-results__option {
  line-height: 20px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

abbr[title], abbr[data-original-title]{
  border-bottom: 0;
  text-decoration: underline dotted;
}

#globalHelpModal a, #globalHelpModal a:hover, #globalHelpModal a:active{
  color: #686868;
}

.alert a{
  color: #686868 !important;
}

footer .beta-warning{
  font-size: 12px;
}

footer a, footer a:hover, footer a:focus, footer a:active{
  color: #e4e7ea;
}

footer .beta-warning a{
  color: white;
  text-decoration: underline dashed;
}

.monospaced {
  font-family: 'Roboto Mono', monospace !important;
}

.modal{
  overflow-y: scroll;
}

.jsPanel-theme-default{
  z-index: 1000 !important;
}

.font-poppins{
  font-family: 'Poppins', sans-serif !important;
}

a.tab span.font-poppins{
  font-size: 12px !important;
  padding-left: 5px;
}

.revision-delete-icon{
  cursor:pointer;font-size: 16px;position: absolute;right: 10px;margin-top: -31px;animation: 1s all;z-index: 9999;padding: 4px 8px;border-left: 1px solid;
}

.revision-delete-icon:hover{
  color: #ff4444;
}

div.modal#globalHelpModal{z-index: 9999}

i.fa.fa-search.search-icon-input{
  position: absolute;
  font-size: 14px;
  top: 11px;
  color: #aaa;
}
input.search-input.full-width.form-control{
  padding-left: 18px;
}

.select2-selection.select2-selection--multiple ul,.select2-selection.select2-selection--single ul{padding-left: 20px !important;}
.select2-selection.select2-selection--multiple i.fa.fa-search.search-icon-input,.select2-selection.select2-selection--single i.fa.fa-search.search-icon-input{
  top: 8px;
}
div.daterangepicker.show-calendar .daterangepicker_input{
  visibility: hidden !important;
  position: absolute !important;
  height: 0 !important;
}